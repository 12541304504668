<template>
    <Header/>
    <Socialheader/>
    <Socialservice/>
    <Footer/>
</template>

<script>

import Header from '@/components/header.vue'
import Footer from '@/components/footer.vue'
import Socialservice from '@/components/Socialservice.vue'
import Socialheader from '@/components/Socialheader.vue'

export default {
  components: {
     Header,
    Footer,
    Socialservice,
    Socialheader
  }
}
</script>