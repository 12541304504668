<template>
    <section id="Socialservice" class="services">
      <div class="container">

        <div class="section-title">
          <h2>What we Do</h2>
          <p style="padding-top:20px">We provide Social media marketing to enhance brand promotion, find the required audience, achieve target goals, and enhance content marketing through posts and tweets and use multimedia to promote ads.</p>
        </div>

        <div class="row" style="padding-top:30px">
          <div class="col-lg-3 col-md-6 col-12 d-flex align-items-stretch" v-for="(obj, key) in mobileappJSON" :key="key">
            <div class="icon-box">
            <Image :fpath=" obj.path " class="serviceimage"/>
              <h4 style="font-size: 15px;">{{ obj.header }}</h4>
                <p>{{ obj.caption }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
</template>
<script>
import Image from '@/components/Image.vue'
export default {
    components: {
    Image
  },
  data: () =>({
    mobileappJSON: [
      {
        path: 'services/Facebook-Ads.jpg',
        header: 'Face book Ads',
        caption: 'To post ads on Face Book, social media uses various tools that offer effective audience reception. The ad posts in them are better reached if boosted. Organic search query will also be enhanced. In a place of million people consuming visual data, an ad should stand out or could be boosted to acquire target.'
      },
      {
        path: 'services/Twitter-Ads.jpg',
        header: 'Twitter Ads',
        caption: 'Ad placement in Twitter media is more based on the number of followers in the post to be advertised. An ad is nurtured by the number of followers we have built up over the years. Here the crowd follows and tweets conversation, and an ad in it should be designed communicably.'
      },
      {
        path: 'services/LinkedIn-Ads.jpg',
        header: 'LinkedIn Ads',
        caption: 'In LinkedIn, the crowd has more of a professional outlook; it is a perfect place for posting ads on upcoming jobs, and for sharing general employee details. It serves as a place for professionals to share and post ads related to businesses.'
      },
      {
        path: 'services/Social-Media-Marketing.jpg',
        header: 'Pros of Social Media Marketing',
        caption: 'Marketing in Social media platforms generate more traffic that are target based. A business could use multiple ways to showcase an ad and use various forms to promote an ad. We can also reach out to specific world audience all at one place. This kind of generating ad is a successful endeavour.'
      }
    ]

  })
    
}
</script>
<style>
img.serviceimage {
    width: 100%;
    padding-bottom: 30px;
}
.services .icon-box {
  margin-bottom: 20px;
  padding: 20px 20px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}
section#Socialservice {
    padding: 50px 30px 10px 30px;
}


.services .icon-box h4 {
  font-weight: 700;
  margin-bottom: 15px;
  
}

.services .icon-box h4 {
  color: #05579e;
  transition: 0.3s;
}

.services .icon-box h4 a:hover {
  color: #0880e8;
}

.services .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 20px;
}

</style>